/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
  Link,
} from 'react-router-dom';
import { GoogleAuthProvider } from 'firebase/auth';
import ImageGen from './components/ImageGen';

import { ProvideAuth, useAuth } from './use-auth';
import LoginPage from './components/Auth/LoginPage';
import Copyright from './components/Copyright';
import SignUpPage from './components/Auth/SignUpPage';
import ResponsiveAppBar from './components/NavBar';
import useGoogleOneTap from './hooks/useGoogleOneTap';
import UserService from './services/user.service';

interface GenerateProps {
  onUpdatedCredits: (credits: number) => void;
}

const Generate: React.FC<GenerateProps> = ({ onUpdatedCredits }: GenerateProps) => (
  <Container maxWidth="xl">
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <ImageGen onUpdatedCredits={onUpdatedCredits} />
      <Copyright />
    </Box>
  </Container>
);

const SmartAIHome: React.FC = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/generate');
  });
  return (
    <div />
  );
};

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth?.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function Layout() {
  return (
    <div>
      <ul>
        <li>
          <Link to="/">SmartAI Home</Link>
        </li>
        <li>
          <Link to="/login">Login</Link>
        </li>
        <li>
          <Link to="/generate">SmartAI Image Generator</Link>
        </li>
      </ul>

      <Outlet />
    </div>
  );
}

// export function AuthStatus() {
//   let auth = useAuth();
//   let navigate = useNavigate();
//   // console.log("Auth: ", auth.user.auth.currentUser);

//   if (!auth.user) {
//     return <p>You are not logged in.</p>;
//   }

//   return (
//     <div>
//       <Typography>
//         <b>Welcome {auth.user.email}</b>
//       </Typography>
//       <Typography variant="overline">
//           <b>Copy Access Token</b>
//         </Typography>
//       <IconButton
//         sx={{ color: "rgba(255, 255, 255, 0.54)" }}
//         onClick={async () => {
//           if (auth.user) {
//             navigator.clipboard.writeText(await auth.user.auth.currentUser.getIdToken(true));
//           }
//         }}
//       >
//         <ContentCopy />
//       </IconButton>
//       <Button
//         onClick={() => {
//           auth.signout(() => navigate("/"));
//         }}
//       >
//         Sign out
//       </Button>
//     </div>
//   );
// }

const App: React.FC = (props) => {
  const [credits, setCredits] = React.useState(0);
  return (
    <ProvideAuth>
      <Routes>
        <Route element={<ResponsiveAppBar credits={credits} />}>
          <Route path="/" element={<SmartAIHome />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route
            path="/generate"
            element={(
              <RequireAuth>
                <Generate onUpdatedCredits={(updatedCredits) => setCredits(updatedCredits)} />
              </RequireAuth>
            )}
          />
        </Route>
      </Routes>
    </ProvideAuth>
  );
};

// const App: React.FC = () => {
//   return (
// <Container maxWidth="xl">
//   <Box sx={{
//     display: 'flex',
//     justifyContent: 'center',
//     flexDirection: 'column',
//  }}>
//     <ImageGen />
//     <Copyright />
//   </Box>
// </Container>
//   );
// }

export default App;
