/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../use-auth';

const pages = [
  {
    name: 'Generate',
    href: '/generate',
  },
  // {
  //   name: "About",
  //   href: "https://smartai.systems"
  // }
];

interface ResponsiveAppBarProps {
  credits: number;
}

function ResponsiveAppBar({ credits }: ResponsiveAppBarProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const handleCopyIdToken = async () => {
    navigator.clipboard.writeText(await auth?.user?.getIdToken(true) || '');
    handleCloseUserMenu();
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          {/* <img src="https://images.squarespace-cdn.com/content/v1/62faf2fc45deeb0598da320c/7202bad9-0d97-4e90-b5af-55aaed6158f6/bert.bits_a_logo_of_a_software_consulting_company_artificial_in_78e87f30-2e0f-4f4a-9bb1-86686d4fcbfa.jpg?format=25w" /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img style={{ borderRadius: '5px', maxHeight: 50, marginTop: 10 }} src="https://smartai.studio/static/media/logo2_tiny.716a54b165dc1f684e9c.png" alt="SmartAI" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    navigate(page.href); handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem key="About" href="https://smartai.systems">
                <Typography textAlign="center">About</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img style={{ borderRadius: '5px', maxHeight: 50, marginTop: 10 }} src="https://smartai.studio/static/media/logo2_tiny.716a54b165dc1f684e9c.png" alt="SmartAI" />

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => { navigate(page.href); handleCloseNavMenu(); }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
            <Button
              key="About"
              onClick={handleCloseNavMenu}
              href="https://smartai.systems"
              target="_blank"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              About
            </Button>
          </Box>
          { auth?.user?.email
            ? (
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>
                <AccountBalanceWalletIcon sx={{ display: { xs: 'none', md: 'flex' } }} />

                <Typography
                  variant="body2"
                  sx={{
                    ml: 1, mr: 2, display: { xs: 'center', md: 'flex' }, textAlign: 'center', alignItems: 'center',
                  }}
                >
                  { auth?.user?.email ? credits : ''}
                </Typography>
              </Box>
            )
            : <> </>}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={auth?.user?.email ? auth.user.email.toUpperCase() : ''} src="/helm" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {auth?.user
                ? (
                  <>
                    <MenuItem key="Copy idToken" onClick={handleCopyIdToken}>
                      <Typography textAlign="center">Copy idToken</Typography>
                    </MenuItem>
                    <MenuItem key="Sign Out" onClick={() => { auth.signout(() => navigate('/')); handleCloseUserMenu(); }}>
                      <Typography textAlign="center">Sign Out</Typography>
                    </MenuItem>
                  </>
                )
                : (
                  <MenuItem key="Sign In" onClick={() => { navigate('/login'); handleCloseUserMenu(); }}>
                    <Typography textAlign="center">Sign In</Typography>
                  </MenuItem>
                )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Outlet />
    </AppBar>
  );
}
export default ResponsiveAppBar;
