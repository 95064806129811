/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useContext, createContext,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  signInWithRedirect,
  signInWithCredential,
  getRedirectResult,
  signInWithPopup,
  getAdditionalUserInfo,
  Auth,
  User,
  AdditionalUserInfo,
  UserCredential,
  AuthCredential,
} from 'firebase/auth';
import UserService from './services/user.service';

// const TENANT_ID = 'ai-stock-image-w22vb';
// const TENANT_ID = 'realm-worlds-1pwl0';
// const TENANT_ID = 'demo-app-9qp06';
const TENANT_ID = 'vnbo-o2pa7';
// Add your Firebase credentials
firebase.initializeApp({
  apiKey: 'AIzaSyBEN3XYDlj3LcnuZo8O9U2_5_zL3SHshxM',
  authDomain: 'smartai-dev-poc-image-gen.firebaseapp.com',
  projectId: 'smartai-dev-poc-image-gen',
  appId: '1:500182862859:web:476c1bda4b3f5f69b00b0e',
});
const actionCodeSettings = {
  url: `https://${process.env.WEB_URL}/confirmEmail`,
  handleCodeInApp: false,
  dynamicLinkDomain: `${process.env.WEB_DOMAIN}`,
};
const authContext = createContext<ProvideAuthProps | null>(null);
const googleProvider = new firebase.auth.GoogleAuthProvider();
firebase.auth().useDeviceLanguage();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
// export const useGoogleProvider = () => useContext(googleProvider);
// Provider hook that creates auth object and handles state
interface ProvideAuthProps {
  user: firebase.User | null,
  signin: Function,
  signup: Function,
  signout: Function,
  sendPasswordResetEmail: Function,
  confirmPasswordReset: Function,
  changePassword: Function,
  signInWithRedirectFlow: Function,
  signInWithCredentialFlow: Function,
  getRedirectResultFlow: Function,
  signInWithPopupFlow: Function,
  sendEmailConfirmation: Function,
  confirmEmail: Function,
  getAdditionalUserInfoFlow: Function,
  updateUserProfile: Function,
}
function useProvideAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const auth = firebase.auth();
  auth.tenantId = TENANT_ID;
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const getAdditionalUserInfoFlow = (result: UserCredential) => getAdditionalUserInfo(result);
  const signin = (email: string, password: string) => auth
    .signInWithEmailAndPassword(email, password)
    .then((response: firebase.auth.UserCredential) => {
      setUser(response.user as firebase.User);
      return response.user;
    });
  const signInWithCredentialFlow = async (credential: AuthCredential) => signInWithCredential(
    auth,
    credential,
  )
    .then((result: UserCredential) => {
      console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      setUser(result.user as firebase.User);
      // IdP data available using getAdditionalUserInfo(result)
      return result;
    });

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const reauthUser = await auth?.currentUser
        ?.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(auth?.currentUser?.email || '', oldPassword),
        );
      console.log('SUCCESS: User reauthenticated', reauthUser);
      await auth?.currentUser?.updatePassword(newPassword);
      console.log('SUCCESS: Password changed');
      return true;
    } catch (error) {
      console.log('ERROR: Password change failed', error);
      return false;
    }
  };
  const signInWithPopupFlow = async () => signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      setUser(result.user as firebase.User);

      // IdP data available using getAdditionalUserInfo(result)
      return result;
    });
  const signInWithRedirectFlow = async () => signInWithRedirect(auth, googleProvider)
    .then((result) => {
      console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      const { user } = result;
      setUser(user as firebase.User);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    });
  const getRedirectResultFlow = async () => getRedirectResult(auth)
    .then((result) => {
      console.log('SUCCESS: Signin with Google Success: ', result);
      // This gives you a Google Access Token. You can use it to access Google APIs.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;

      // The signed-in user info.
      setUser(result?.user as firebase.User);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    });
  const signup = async ({ email, password, displayName }:
  { email: string, password: string, displayName: string }) => UserService
    .CreateNewUserAccount({ email, password, displayName });
  const updateUserProfile = ({ userInfo }
  : { userInfo: { displayName: any; photoURL: any; } }) => {
    const user = firebase.auth().currentUser;
    user?.updateProfile({
      displayName: userInfo.displayName,
      photoURL: userInfo.photoURL,
    });
    const userNew = firebase.auth().currentUser;
    setUser(userNew as firebase.User);
  };
  const signout = () => auth
    .signOut()
    .then(() => {
      setUser(null);
    });
  const sendPasswordResetEmail = (email: string) => auth
    .sendPasswordResetEmail(email)
    .then(() => true);
  const confirmPasswordReset = (code: string, password: string) => auth
    .confirmPasswordReset(code, password)
    .then(() => true);
  const sendEmailConfirmation = () => auth?.currentUser?.sendEmailVerification(actionCodeSettings)
    .then(() => true);

  const confirmEmail = (code: string) => auth
    .applyActionCode(code);
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user as firebase.User);
      } else {
        setUser(null);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    changePassword,
    signInWithRedirectFlow,
    signInWithCredentialFlow,
    getRedirectResultFlow,
    signInWithPopupFlow,
    sendEmailConfirmation,
    confirmEmail,
    getAdditionalUserInfoFlow,
    updateUserProfile,
  };
}
