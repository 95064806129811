/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable vars-on-top */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Backdrop,
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import { MergeType } from '@mui/icons-material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useAuth } from '../use-auth';
import GenImageOptions from './GenImageOptions';
import { useSSGPClient } from '../hooks/useSSGPClient';
import { UserWallet } from '../generated';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://stockimage.ssgp.smartai.digital';
// const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

interface ImageGenProps {
  onUpdatedCredits: (credits: number) => void;
}

export default function ImageGen({ onUpdatedCredits } : ImageGenProps) {
  const auth = useAuth();
  const ssgpClient = useSSGPClient();

  const [images, setImages] = React.useState(Array<any>);
  const [imagesInBatch, setImagesInBatch] = React.useState(2);
  const [numBatches, setNumBatches] = React.useState(1);
  const [width, setWidth] = React.useState(768);
  const [plms, setPlms] = React.useState(false);
  const [height, setHeight] = React.useState(768);
  const [prompt, setPrompt] = React.useState('');
  const [negativePrompt, setNegativePrompt] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [steps, setSteps] = React.useState(50);
  const [seed, setSeed] = React.useState(-1);
  const [ddimEta, setDdimEta] = React.useState(0.0);
  const [clipScale, setClipScale] = React.useState(6.5);
  const [sdVersion, setSdVersion] = React.useState('v2');
  const [upscaleFlag, setUpscaleFlag] = React.useState(false);
  // const [executionId, setExecutionId] = React.useState('');
  const [isMobile, setIsMobile] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = React.useState('');
  const [img2imgInitImage, setImg2imgInitImage] = React.useState('');
  const [strength, setStrength] = React.useState(0.25);
  const [use512, setUse512] = React.useState(false);
  const [guessMode, setGuessMode] = React.useState(false);
  const [cannyLowThreshold, setCannyLowThreshold] = React.useState(100);
  const [cannyHighThreshold, setCannyHighThreshold] = React.useState(200);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleImagePreview = (imgSrc: string) => {
    setImgPreviewSrc(imgSrc);
    setShowPreview(true);
  };
  const handleCreateVariations = async (
    imgSrc: string,
    _prompt: string,
    _negativePrompt: string,
  ) => {
    setPrompt(_prompt);
    setNegativePrompt(_negativePrompt);
    callStable(true, imgSrc, _prompt, _negativePrompt);
  };
  const handleUpscale = async (imgSrc: string) => {
    upscale(imgSrc);
  };

  const handleSetCredits = (newCredits: number) => {
    onUpdatedCredits(newCredits);
  };
  // Get user's images
  // @ts-ignore
  useEffect(() => {
    const mounted = true;
    if (mounted) {
      // create a new SSGPClient
      if (auth?.user?.uid) {
        console.log('calling getApiUserWallet');
        console.log('auth: ', auth.user.uid);
        ssgpClient?.user.getApiUserWallet(auth.user.uid)
          .then((value: UserWallet) => {
            console.log('value: ', value);
          })
          .catch((error: Error) => {
            console.log('ERROR Getting user wallet error: ', error);
          });
      }
      window.addEventListener('resize', handleResize);

      auth?.user?.getIdToken().then((idToken: string) => {
        axios({
          url: `${BASE_URL}/api/user/me/workflows?pageSize=25`,
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }).then((res) => {
          console.log(res.data);
          let imgArr: string[] = [];
          for (let i = 0; i < res.data.length; i += 1) {
            if (
              'workflowResults' in res.data[i]
            && res.data[i].workflowResults
            ) {
              imgArr = imgArr.concat(
                res.data[i].workflowResults.map((workflowResult: any) => {
                  if (!workflowResult.imageUrl.includes('grid')) {
                    return {
                      src: workflowResult.imageUrl,
                      prompt: workflowResult.prompt,
                      negativePrompt: workflowResult.negativePrompt,
                      workflowId: res.data[i].executionId,
                    };
                  } return null;
                }),
              );
              imgArr = imgArr.filter((img: any) => img !== null);
            }
          }
          // @ts-ignore
          setImages(imgArr);
        });

        axios({
          url: `${BASE_URL}/api/user/me/wallet`,
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }).then((walletRes) => {
        // console.log('walletRes: ', walletRes);
          handleSetCredits(
            Number(walletRes.data.creditPackCreditsRemaining)
           + Number(walletRes.data.subscriptionCreditsRemaining),
          );
        });
      });
      handleResize();
    }
  }, []);

  const upscale = async (imgSrc: string) => {
    try {
      setLoading(true);
      var waiting = true;
      const body = {
        jobs: [
          {
            feature: 'modifyImageUpscaleSuperRes',
            parameters: {
              image_urls: [imgSrc],
              // @ts-ignore
              dejpeg: false,
              models: ['ESRGAN_and_FatalPixels'],
              // "models": ["ESRGAN_and_PSNR"],
              // "models": ["ESRGAN"],
              // "models": ["PSNR"],
              sharpen: false,
              interpolation: 26,
              sharpen_before: false,
              sharpen_after: false,
              models_in_filename: true,
              seamless: 'replicate',
              fp16: true,
              cache_max_split_depth: true,
              binary_alpha: true,
              ternary_alpha: false,
              alpha_threshold: 0.1,
              alpha_boundary_offset: 0.2,
              alpha_mode: 'alpha_separately',
              verbose: false,
            },
          },
        ],
      };
      const upscaleReq = await axios.post(
        `${BASE_URL}/api/user/me/workflows`,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await auth?.user?.getIdToken()}`,
          },
        },
      );
      if (upscaleReq.status === 200) {
        console.log('stableReq ---', upscaleReq.data.jobId);
        // await setExecutionId(stableReq.data.name)
      }
      while (waiting) {
        try {
          const jobRes = await axios({
            url: `${BASE_URL}/api/user/me/workflows`,
            method: 'get',
            params: {
              jobId: upscaleReq.data.jobId,
            },
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await auth?.user?.getIdToken()}`,
            },
          });
          if (
            jobRes.status === 200
            && jobRes.data.status !== 'Active'
            && 'workflowResults' in jobRes.data
          ) {
            setLoading(false);
            console.log('jobRes: ', jobRes);
            var imgArr = jobRes.data.workflowResults.map((workflowResult: any) => {
              if (!workflowResult.imageUrl.includes('grid')) {
                return {
                  src: workflowResult.imageUrl,
                  prompt: workflowResult.prompt,
                  negativePrompt: workflowResult.negativePrompt,
                  workflowId: jobRes.data.userWorkflowId,
                };
              } return null;
            });
            imgArr = imgArr.filter((img: any) => img !== null);
            // @ts-ignore
            setImages((curArr) => [...imgArr, ...curArr]);
          } else {
            setLoading(false);
            waiting = false;
            console.log('jobRes: ', jobRes);
          }
        } catch (error) {
          console.log('error: ', error);
          setLoading(false);
          waiting = false;
        }
        console.log('retrying....waiting for job to be complete...');
        await new Promise((r) => setTimeout(r, 2000));
      }
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
    }
  };
  const callStable = async (variations: boolean, initImage = '', _prompt = prompt, _negativePrompt = negativePrompt) => {
    setLoading(true);
    let waiting = true;
    let featureName = '';
    // Check if we're trying to vary using the same image
    if (variations) {
      switch (sdVersion) {
        case 'SDv1':
          featureName = 'imageGenerationStableDiffusionImg2Img';
          break;
        case 'SDv2':
          featureName = 'imageGenerationStableDiffusionv2Img2Img';
          break;
        default:
          featureName = 'imageGenerationStableDiffusionv2Img2Img';
          break;
      }
    } else {
      switch (sdVersion) {
        case 'SDv1':
          featureName = 'imageGenerationStableDiffusion';
          break;
        case 'SDv2':
          featureName = 'imageGenerationStableDiffusionv2';
          break;
        case 'ControlNet - Canny':
          featureName = 'imageGenerationControlNetCanny2Img';
          break;
        case 'ControlNet - Hed':
          featureName = 'imageGenerationControlNetHed2Img';
          break;
        case 'ControlNet - Draw':
          featureName = 'imageGenerationControlNetDraw2Img';
          break;
        case 'ControlNet - Depth':
          featureName = 'imageGenerationControlNetDepth2Img';
          break;
        default:
          featureName = 'imageGenerationStableDiffusionv2';
          break;
      }
    }
    const body = {
      jobs: [
        {
          feature: featureName,
          parameters: {
            prompt: _prompt,
            negative_prompt: _negativePrompt,
            init_image: variations ? initImage : img2imgInitImage,
            strength,
            plms,
            ddim_steps: steps,
            n_samples: imagesInBatch,
            scale: clipScale,
            outdir: 'outputs/txt2img-samples',
            skip_grid: true,
            skip_save: false,
            laion400m: false,
            fixed_code: false,
            ddim_eta: ddimEta,
            n_iter: numBatches,
            use_512: use512,
            guess_mode: guessMode,
            save_memory: false,
            canny_low_threshold: cannyLowThreshold,
            canny_high_threshold: cannyHighThreshold,
            W: width,
            H: height,
            C: 4,
            f: 8,
            n_rows: 0,
            seed: seed > 0 ? seed : randomIntFromInterval(1, 10000000),
            // "seed": 1234623,
            precision: 'autocast',
            enforce_safety: false,
          },
        },
      ],
    };
    if (upscaleFlag) {
      // @ts-ignore
      body.jobs.push({
        feature: 'modifyImageUpscaleSuperRes',
        parameters: {
          // @ts-ignore
          dejpeg: false,
          models: ['ESRGAN_and_FatalPixels'],
          // "models": ["ESRGAN_and_PSNR"],
          // "models": ["ESRGAN"],
          // "models": ["PSNR"],
          image_urls: [],
          sharpen: false,
          interpolation: 26,
          sharpen_before: false,
          sharpen_after: false,
          models_in_filename: true,
          seamless: 'replicate',
          fp16: true,
          cache_max_split_depth: true,
          binary_alpha: true,
          ternary_alpha: false,
          alpha_threshold: 0.1,
          alpha_boundary_offset: 0.2,
          alpha_mode: 'alpha_separately',
          verbose: false,
        },
      });
    }
    console.log('stable body: ', body);
    const stableReq = await axios.post(
      `${BASE_URL}/api/user/me/workflows`,
      body,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await auth?.user?.getIdToken()}`,
        },
      },
    );
    if (stableReq.status === 200) {
      console.log('stableReq ---', stableReq.data.userWorkflowId);
      // await setExecutionId(stableReq.data.name)
    }
    while (waiting) {
      try {
        const jobRes = await axios({
          url: `${BASE_URL}/api/user/me/workflows/${stableReq.data.userWorkflowId}`,
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await auth?.user?.getIdToken()}`,
          },
        });
        if (
          jobRes.status === 200
          && jobRes.data.status !== 'Active'
          && 'workflowResults' in jobRes.data
        ) {
          setLoading(false);
          waiting = false;
          console.log('jobRes: ', jobRes);
          var imgArr = jobRes.data.workflowResults.map((workflowResult: any) => {
            if (!workflowResult.imageUrl.includes('grid')) {
              return {
                src: workflowResult.imageUrl,
                prompt: workflowResult.prompt,
                negativePrompt: workflowResult.negativePrompt,
                workflowId: jobRes.data.userWorkflowId,
              };
            } return null;
          });
          imgArr = imgArr.filter((img: any) => img !== null);
          // @ts-ignore
          setImages((curArr) => [...imgArr, ...curArr]);
          const walletRes = await axios({
            url: `${BASE_URL}/api/user/me/wallet`,
            method: 'get',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await auth?.user?.getIdToken()}`,
            },
          });
          // console.log('walletRes: ', walletRes);
          handleSetCredits(
            Number(walletRes.data.creditPackCreditsRemaining)
             + Number(walletRes.data.subscriptionCreditsRemaining),
          );
        }
      } catch (error) {
        console.log('error: ', error);
      }
      console.log('retrying....waiting for job to be complete...');
      await new Promise((r) => setTimeout(r, 2000));
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off"
    >
      {/* <Typography variant="h4" component="h1">
          SmartAI Image Generator
        </Typography> */}
      <GenImageOptions
        prompt={prompt}
        negativePrompt={negativePrompt}
        clipScale={clipScale}
        ddimEta={ddimEta}
        numBatches={numBatches}
        imagesInBatch={imagesInBatch}
        width={width}
        height={height}
        sdVersion={sdVersion}
        steps={steps}
        seed={seed}
        plms={plms}
        upscaleFlag={upscaleFlag}
        initImage={img2imgInitImage}
        strength={strength}
        use512={use512}
        guessMode={guessMode}
        cannyLowThreshold={cannyLowThreshold}
        cannyHighThreshold={cannyHighThreshold}
        onUpdatedPrompt={setPrompt}
        onUpdatedNegativePrompt={setNegativePrompt}
        onUpdatedClipScale={setClipScale}
        onUpdatedImagesInBatch={setImagesInBatch}
        onUpdatedNumBatches={setNumBatches}
        onUpdatedUpscaleFlag={setUpscaleFlag}
        onUpdatedDdimEta={setDdimEta}
        onUpdatedWidth={setWidth}
        onUpdatedHeight={setHeight}
        onUpdatedSdVersion={setSdVersion}
        onUpdatedSteps={setSteps}
        onUpdatedSeed={setSeed}
        onUpdatedPlms={setPlms}
        onUpdatedUse512={setUse512}
        onUpdatedGuessMode={setGuessMode}
        onUpdatedCannyLowThreshold={setCannyLowThreshold}
        onUpdatedCannyHighThreshold={setCannyHighThreshold}
        onCallStable={callStable}
        onUpdateInitImage={setImg2imgInitImage}
        onUpdatedStrength={setStrength}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={loading}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showVariationsPopup}
      >
      </Backdrop> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showPreview}
        onClick={() => setShowPreview(false)}
      >
        <img
          src={imgPreviewSrc}
          alt={imgPreviewSrc}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </Backdrop>
      <ImageList
        variant="quilted"
        cols={isMobile ? 2 : 2}
        gap={15}
      >
        {
          // @ts-ignore
          images
            .filter(
              (image: any) => !image.src.endsWith('.json')
                && !image.src.endsWith('grid-0000.png'),
            )
            .map((image) => (
              <ImageListItem key={image.src} cols={1} rows={isMobile ? 1 : 1}>
                <img
                  style={{ borderRadius: 30 }}
                  src={image.src}
                  srcSet={image.src}
                  alt={image.src}
                  loading="lazy"
                  onClick={() => handleImagePreview(image.src)}
                  // onContextMenu={() => handleCreateVariations(image.src, image.prompt)}
                />
                <ImageListItemBar
                  // @ts-ignore
                  title={image.prompt}
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, '
                      + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    height: '15%',
                  }}
                  actionIcon={(
                    <Tooltip title="Copy Image Prompt" placement="top">
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 1)' }}
                        aria-label={`info about ${image}`}
                        onClick={() => {
                          navigator.clipboard.writeText(image.prompt);
                          setPrompt(image.prompt);
                          setNegativePrompt(image.negativePrompt);
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                  )}
                />
                <ImageListItemBar
                  position="top"
                  actionPosition="left"
                  sx={{ background: 'rgba(0, 0, 0, 0)' }}
                  actionIcon={(
                    <>
                      <Tooltip title="Upscale Image" placement="bottom">
                        <IconButton
                          size="large"
                          color="secondary"
                          sx={{ ml: 1, mt: 1, color: 'rgba(255, 255, 255, 1)' }}
                          aria-label={`info about ${image}`}
                          onClick={() => {
                            handleUpscale(image.src);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth',
                            });
                          }}
                        >
                          <AspectRatioIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Create Variations of Image"
                        placement="bottom"
                      >
                        <IconButton
                          size="large"
                          color="secondary"
                          sx={{ ml: 1, mt: 1, color: 'rgba(255, 255, 255, 1)' }}
                          aria-label={`info about ${image}`}
                          onClick={() => {
                            handleCreateVariations(image.src, image.prompt, image.negativePrompt);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth',
                            });
                          }}
                        >
                          <MergeType fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                />
              </ImageListItem>
            ))
        }
      </ImageList>
    </Box>
  );
}
