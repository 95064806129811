// export const apiURI = "https://localhost:7088"
// TODO - Move this to an environment variable
// export const apiURI = "https://smartai-stockimage-api-dev-us-east1-3vbtmsim5q-ue.a.run.app"; // dev
const apiURI = 'https://qa-xzhgq.api.smartai.studio';
const SSGPBase = 'https://stockimage.ssgp.smartai.digital';
export {
  apiURI,
  SSGPBase,
};
// export const apiURI = "https://smartai-stockimage-api-service-master-us-east1-3vbtmsim5q-ue.a.run.app/"; // prod
