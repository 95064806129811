/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/function-component-definition */

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleAuthProvider } from 'firebase/auth';
import {
  Avatar, Box, CircularProgress, FormHelperText,
} from '@mui/material';
import { useAuth } from '../../use-auth';
import UserService from '../../services/user.service';
import useGoogleOneTap from '../../hooks/useGoogleOneTap';

const LoginPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [authLoading, setAuthLoading] = React.useState(false);
  const [showAnimation] = React.useState(false);
  const [authError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleLoginWithGoogleButton = async () => {
    console.log('login with google');
    setAuthLoading(true);
    try {
      const result = await auth?.signInWithPopupFlow();
      // if you get here, you have signed in.
      // check if the user new.
      const userDetails = auth?.getAdditionalUserInfoFlow(result);
      // const currentUser = result.user;
      // const idToken = await auth?.user?.getIdToken();
      if (userDetails?.isNewUser) {
        // if the user is new, then we need to notify the backend that this is a new user.
        prompt('This is a new user...you will need to create an SSGP account before proceeding.');
      }
    } catch (error: any) {
      setError(true);
      setErrorMessage('Could not sign in with Google. Please try again.');
    }
  };

  const handleGoogleOneTapResponse = async (response: any) => {
    setAuthLoading(true);
    try {
      // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      const { credential } = response;
      console.log('google one tap response: ', response);
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      console.log('googleAuthCredential: ', googleAuthCredential);
      const result = await auth?.signInWithCredentialFlow(googleAuthCredential);
      console.log('result: ', result);
      const userDetails = auth?.getAdditionalUserInfoFlow(result);
      console.log('userDetails: ', userDetails);
      const currentUser = result.user;
      console.log('Successfully created account using Google');
      const { isNewUser } = userDetails;
      if (isNewUser) {
        console.log('Creating New user');
        const createNewUserRes = await UserService.CreateNewUserAccount({
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoUrl: currentUser.photoURL,
          userId: currentUser.uid,
        });
        console.log(createNewUserRes);
        // if (createNewUserRes?.userId) {
        //   navigate('/');
        // }
      } else {
        // navigate('/');
      }
    } catch (error) {
      console.log('ERROR: Signin with Google One Tap Error: ', error);
    }
    setAuthLoading(false);
  };

  const handleGoogleButtonClick = (isShowing: boolean) => {
    if (isShowing) {
      setAuthLoading(true);
      console.log('Google OneTap Button Clicked');
      console.log('event: ', isShowing);
    } else {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    // eslint-disable-next-line no-console
    onDisplay: (isShowing: any) => handleGoogleButtonClick(isShowing),
    showPrompt: true,
    disabled: !!auth?.user,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: !!auth?.user,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });
  const handleLogin = async () => {
    await auth?.signin(email, password);
    navigate('/generate');
    console.log('logged in. navigating to generate');
  };
  React.useEffect(() => {
    if (auth?.user) {
      navigate('/generate');
    }
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {authLoading
        ? (
          showAnimation ? <CircularProgress color="inherit" /> : (<></>)
        ) : (
          <Paper
            sx={{
              width: 300,
              mx: 'auto', // margin left & right
              my: 4, // margin top & botom
              py: 3, // padding top & bottom
              px: 2, // padding left & right
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 'sm',
              boxShadow: 'md',
            }}
            variant="outlined"
          >
            <div>
              <Typography variant="h4" component="h1">
                <b>SmartAI Generator</b>
              </Typography>
              <Typography variant="body2">Sign in to continue.</Typography>
            </div>
            <TextField
            // html input attribute
              name="email"
              type="email"
              placeholder="bert@smartai.solutions"
              onChange={(e) => setEmail(e.target.value)}
            // pass down to FormLabel as children
              label="Email"
            />
            <TextField
              name="password"
              type="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
            />
            {authError ? (
              <FormHelperText
                sx={() => ({
                  mx: 'auto',
                  color: 'red',
                })}
              >
                {errorMessage}
              </FormHelperText>
            ) : (
              <></>
            )}
            <Button sx={{ mt: 1 /* margin top */ }} onClick={handleLogin}>
              Log in
            </Button>
            <Typography align="center">or</Typography>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              startIcon={<Avatar sx={{ width: 24, height: 24 }} src="./images/google_icon.svg" />}
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLoginWithGoogleButton}
            >
              with Google
            </Button>
            <Typography
              fontSize="sm"
              sx={{ alignSelf: 'center' }}
            >
              Don&apos;t have an account?
              <Link to="/signup">Sign up</Link>
            </Typography>
          </Paper>
        )}
    </Box>
  );
};

export default LoginPage;
