/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  solid, regular, brands, icon,
} from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import * as React from 'react';

interface GenImageOptionsProps {
  prompt: string;
  negativePrompt: string;
  clipScale: number;
  ddimEta: number;
  numBatches: number;
  imagesInBatch: number;
  width: number;
  height: number;
  sdVersion: string;
  steps: number;
  seed: number;
  plms: boolean;
  upscaleFlag: boolean;
  initImage: string;
  strength: number;
  use512: boolean;
  guessMode: boolean;
  cannyLowThreshold: number;
  cannyHighThreshold: number;
  onUpdatedPrompt: (prompt: string) => void;
  onUpdatedNegativePrompt: (negativePrompt: string) => void;
  onUpdatedClipScale: (clipScale: number) => void;
  onUpdatedDdimEta: (ddimEta: number) => void;
  onUpdatedNumBatches: (numBatches: number) => void;
  onUpdatedImagesInBatch: (imagesInBatch: number) => void;
  onUpdatedWidth: (width: number) => void;
  onUpdatedHeight: (height: number) => void;
  onUpdatedSdVersion: (sdVersion: string) => void;
  onUpdatedSteps: (steps: number) => void;
  onUpdatedSeed: (seed: number) => void;
  onUpdatedPlms: (plms: boolean) => void;
  onUpdatedUpscaleFlag: (upscaleFlag: boolean) => void;
  onCallStable: (variations: boolean) => void;
  onUpdateInitImage: (initImage: string) => void;
  onUpdatedStrength: (strength: number) => void;
  onUpdatedUse512: (use512: boolean) => void;
  onUpdatedGuessMode: (guessMode: boolean) => void;
  onUpdatedCannyLowThreshold: (cannyLowThreshold: number) => void;
  onUpdatedCannyHighThreshold: (cannyHighThreshold: number) => void;
}
const stepOptions = [5, 10, 15, 20, 25, 40, 50, 80, 100, 150, 200];
const stableDiffusionVersions = ['v1', 'v2', 'ControlNet - Canny', 'ControlNet - Hed', 'ControlNet - Draw', 'ControlNet - Depth'];
const numBatchesOptions = [1, 2, 3];
const imagesInBatchOptions = [1, 2, 3, 4, 5];
const widthOptions = [256, 512, 768, 1024, 1280, 1536, 2048];
const heightOptions = [256, 512, 768, 1024, 1280, 1536, 2048];
const ddimEtaOptions = [
  0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 5.0, 10.0, 50.0, 100.0,
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GenImageOptions: React.FC<GenImageOptionsProps> = ({
  prompt,
  negativePrompt,
  clipScale,
  ddimEta,
  numBatches,
  imagesInBatch,
  width,
  height,
  sdVersion,
  steps,
  seed,
  plms,
  upscaleFlag,
  initImage,
  strength,
  use512,
  guessMode,
  cannyLowThreshold,
  cannyHighThreshold,
  onUpdatedPrompt,
  onUpdatedNegativePrompt,
  onUpdatedClipScale,
  onUpdatedDdimEta,
  onUpdatedNumBatches,
  onUpdatedImagesInBatch,
  onUpdatedWidth,
  onUpdatedHeight,
  onUpdatedSdVersion,
  onUpdatedSteps,
  onUpdatedSeed,
  onUpdatedPlms,
  onUpdatedUpscaleFlag,
  onUpdatedUse512,
  onCallStable,
  onUpdateInitImage,
  onUpdatedStrength,
  onUpdatedGuessMode,
  onUpdatedCannyLowThreshold,
  onUpdatedCannyHighThreshold,
}: GenImageOptionsProps) => {
  const [loading, setLoading] = React.useState(false);
  const [basePrompt, setBasePrompt] = React.useState('');
  const promptHelper = async () => {
    setLoading(true);
    if (basePrompt === '' || prompt.length < 100) {
      setBasePrompt(prompt);
    }
    const data = {
      inputs: basePrompt === '' ? prompt : basePrompt,
      do_sample: true,
      temperature: 0.9,
      top_k: 8,
      max_length: 100,
      repitition_penalty: 1.2,
      num_return_sequences: 3,
      early_stopping: false,
    };
    const response = await fetch(
      `${process.env.REACT_APP_HUGGINGFACE_URL}`,
      {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_HUGGINGFACE_KEY}` },
        method: 'POST',
        body: JSON.stringify(data),
      },
    );
    const result = await response.json();
    if (result.error && result.estimated_time) {
      // try again in estimated time
      setTimeout(promptHelper, result.estimated_time * 1000);
    } else if (result.error) {
      // eslint-disable-next-line no-alert
      alert('Error generating prompt, please try again.');
    } else if (result.length > 0) {
      onUpdatedPrompt(result[0].generated_text);
      setLoading(false);
    }
    console.log(result);
  };
  return (
    <Grid container spacing={2} sx={{ marginTop: '2vh' }}>
      <Grid item xs={12}>
        <Typography color="text.secondary" variant="h4" textAlign="center">
          Start by typing in a prompt and clicking generate.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="text.secondary">
          Add an image to start from, or leave blank to start from a random image.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="filled"
          label="Init Image"
          onChange={(event) => onUpdateInitImage(event.target.value)}
          value={initImage}
          fullWidth
        />
      </Grid>
      { initImage ? (
        <Grid item xs={12}>
          <Slider
            aria-label="Open Clip Guidence Scale"
            valueLabelDisplay="on"
            value={strength}
            onChange={(_event, newValue) => onUpdatedStrength(newValue as number)}
            step={0.05}
            marks
            min={0.05}
            max={1}
          />
        </Grid>
      ) : (<></>)}
      <Grid item xs={12}>
        <TextField
          variant="filled"
          fullWidth
          rows={4}
          sx={{ mb: 2 }}
          id="standard-basic"
          label="Prompt"
          onChange={(event) => onUpdatedPrompt(event.target.value)}
          value={prompt}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            variant="contained"
            onClick={promptHelper}
            sx={{ width: 200 }}
            loading={loading}
            startIcon={<FontAwesomeIcon icon={solid('dice')} />}
          >
            Prompt Helper
          </LoadingButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          rows={4}
          sx={{ mb: 2 }}
          id="standard-basic"
          label="Negative Prompt"
          variant="filled"
          onChange={(event) => onUpdatedNegativePrompt(event.target.value)}
          value={negativePrompt}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <Slider
          aria-label="Open Clip Guidence Scale"
          valueLabelDisplay="on"
          value={clipScale}
          onChange={(_event, newValue) => onUpdatedClipScale(newValue as number)}
          step={0.5}
          marks
          min={0.5}
          max={50}
        />
      </Grid>
      <Grid item>
        <TextField
          sx={{ mb: 2 }}
          id="standard-basic"
          label="Seed"
          type="number"
          variant="standard"
          onChange={(event) => onUpdatedSeed(Number(event.target.value))}
          value={seed}
        />
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 80 }}>
          <InputLabel id="demo-multiple-name-label">ddim ETA</InputLabel>
          <Select
            value={ddimEta}
            onChange={(event) => onUpdatedDdimEta(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {ddimEtaOptions.map((ddimEtaOption) => (
              <MenuItem key={ddimEtaOption} value={ddimEtaOption}>
                {ddimEtaOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 150 }}>
          <InputLabel id="demo-multiple-name-label">Number of Batches</InputLabel>
          <Select
            value={numBatches}
            onChange={(event) => onUpdatedNumBatches(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {numBatchesOptions.map((numBatchesOption) => (
              <MenuItem key={numBatchesOption} value={numBatchesOption}>
                {numBatchesOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 150 }}>
          <InputLabel id="demo-multiple-name-label">Images in Batch</InputLabel>
          <Select
            value={imagesInBatch}
            onChange={(event) => onUpdatedImagesInBatch(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {imagesInBatchOptions.map((imagesInBatchOption) => (
              <MenuItem key={imagesInBatchOption} value={imagesInBatchOption}>
                {imagesInBatchOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 80 }}>
          <InputLabel id="demo-multiple-name-label">Width</InputLabel>
          <Select
            value={width}
            onChange={(event) => onUpdatedWidth(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {widthOptions.map((widthOption) => (
              <MenuItem key={widthOption} value={widthOption}>
                {widthOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 80 }}>
          <InputLabel id="demo-multiple-name-label">Height</InputLabel>
          <Select
            value={height}
            onChange={(event) => onUpdatedHeight(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {heightOptions.map((heightOption) => (
              <MenuItem key={heightOption} value={heightOption}>
                {heightOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 210 }}>
          <InputLabel id="demo-multiple-name-label">Version</InputLabel>
          <Select
            value={sdVersion}
            onChange={(event) => onUpdatedSdVersion(String(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {stableDiffusionVersions.map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, width: 80 }}>
          <InputLabel id="demo-multiple-name-label">Steps</InputLabel>
          <Select
            value={steps}
            onChange={(event) => onUpdatedSteps(Number(event.target.value))}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {stepOptions.map((step) => (
              <MenuItem key={step} value={step}>
                {step}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormGroup sx={{ mr: 3 }}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={use512}
                onChange={() => onUpdatedUse512(!use512)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
          )}
            labelPlacement="start"
            label="Use 512x512 SDv2 Model?"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <FormGroup sx={{ mr: 3 }}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={plms}
                onChange={() => onUpdatedPlms(!plms)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
          )}
            labelPlacement="start"
            label="PLMS?"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <FormGroup sx={{ mr: 3 }}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={upscaleFlag}
                onChange={() => onUpdatedUpscaleFlag(!upscaleFlag)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
          )}
            labelPlacement="start"
            label="Upscale?"
          />
        </FormGroup>
      </Grid>
      {/* If sdVersion conatins ControlNet */}
      {sdVersion.includes('ControlNet') ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center">ControlNet Parameters</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormGroup sx={{ mr: 3 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={guessMode}
                    onChange={() => onUpdatedGuessMode(!guessMode)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
        )}
                labelPlacement="start"
                label="Use Guess Mode?"
              />
            </FormGroup>
          </Grid>
        </>
      ) : <> </>}
      {sdVersion.includes('ControlNet - Canny') ? (
        <>
          <Grid item xs={5}>
            <Slider
              aria-label="Open Clip Guidence Scale"
              valueLabelDisplay="on"
              value={cannyLowThreshold}
              onChange={(_event, newValue) => onUpdatedCannyLowThreshold(newValue as number)}
              step={1}
              marks
              min={1}
              max={255}
            />
          </Grid>
          <Grid item xs={5}>
            <Slider
              aria-label="Open Clip Guidence Scale"
              value={cannyHighThreshold}
              onChange={(_event, newValue) => onUpdatedCannyHighThreshold(newValue as number)}
              step={1}
              marks
              min={cannyLowThreshold}
              max={255}
              valueLabelDisplay="on"
            />
          </Grid>
        </>
      ) : <> </>}
      <Grid item xs={12}>
        {/* center box */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ width: 200 }}
            variant="contained"
            onClick={() => onCallStable(false)}
            startIcon={<FontAwesomeIcon icon={solid('bomb')} />}
            disabled={loading}
          >
            Generate
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GenImageOptions;
