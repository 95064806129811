import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { OpenAPIConfig, SSGPClient } from '../generated';
import { SSGPBase } from '../shared/api.settings';
import { useAuth } from '../use-auth';

const useSSGPClientProvider = () => {
  const [client, setClient] = useState<SSGPClient | null>(null);
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user) {
      auth?.user.getIdToken()
        .then((idToken) => {
          const OpenAPI: OpenAPIConfig = {
            BASE: SSGPBase,
            VERSION: '2.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'include',
            TOKEN: idToken,
            USERNAME: undefined,
            PASSWORD: undefined,
            HEADERS: undefined,
            ENCODE_PATH: undefined,
          };
          setClient(new SSGPClient(OpenAPI));
        });
    }
  }, [auth]);

  return client;
};

const ssgpClientContext = createContext<SSGPClient | null>(null);
function ProvideSSGPClient({ children }: { children: JSX.Element }) {
  const client = useSSGPClientProvider();
  return (
    <ssgpClientContext.Provider value={client}>
      {children}
    </ssgpClientContext.Provider>
  );
}
const useSSGPClient = () => useContext(ssgpClientContext);
export default ProvideSSGPClient;
export {
  useSSGPClient,
};
