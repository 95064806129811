import axios from 'axios';
import { apiURI } from '../shared/api.settings';

interface CreateNewUserAccountProps {
  email: string;
  password: string | null;
  displayName: string | null;
  photoUrl?: string;
  userId?: string | null;
  phoneNumber?: string;
  plan?: string;
}

class UserService {
  /*
  static GetUserCredits = async (id) => {
      return fetch(`${apiURI}/credits?userId=${id}&extendedInfo=false`)
          .then((response) => response.json());
  }
  */
  static GetUserCredits = async (id: string) => fetch(
    `${apiURI}/credits/current?userId=${id}&extendedInfo=false`,
  ).then((response) => response.json());

  static GetUserSubscription = async (id: string, token: string) => fetch(`${apiURI}/subscription/current?userId=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(
    (response) => response.json(),
  );

  static GetUserProfile = async (userId: string, accessToken: string) => axios.get(`${apiURI}/user/${userId}/profile`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  static UpdateUserProfile = async (userId: string, accessToken: string, user: any) => axios.put(
    `${apiURI}/user/${userId}/profile`,
    { ...user },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  static CreateNewUserAccount = async ({
    email,
    password,
    displayName,
    photoUrl = '',
    userId = null,
    phoneNumber = '',
    plan = 'Free',
  }: CreateNewUserAccountProps) => {
    const body = {
      userId,
      email,
      password,
      displayName,
      photoUrl,
      phoneNumber,
      subscriptionTierName: plan,
    };
    console.log('body: ', body);
    return fetch(`${apiURI}/user`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static UpgradeUserAccount = (userId: string, plan: string) => {
    const body = {
      userId,
      subscriptionTierName: plan,
    };
    console.log('body: ', body);
    return axios.put(`${apiURI}/user/subscription/upgrade`, body);
  };

  /*
  static SendPasswordResetEmail = (emailToSendTo) => {
    console.log("emailToSendTo: ", emailToSendTo);
    return axios.post(`${apiURI}/user/password/reset?emailToSendReset=${emailToSendTo}`)
  }
*/

  static SendPasswordResetEmail = async (emailToSendTo: string) => {
    console.log('parameter ', emailToSendTo);

    const url = `${apiURI}/user/password/reset`;

    const body = {
      emailToSendReset: emailToSendTo,
    };

    console.log('calling ', url, ' body: ', body);

    // POST request using fetch with set headers
    const requestOptions: RequestInit = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    fetch(url, requestOptions).then((response) => response.json());
  };
}

export default UserService;
