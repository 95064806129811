/* eslint-disable no-undef */
import { useEffect } from 'react';
import useScript from './useScript';

const scriptFlag = '__googleOneTapScript__';
const googleClientScriptURL = 'https://accounts.google.com/gsi/client';

const useGoogleOneTap = ({
  onDisplay,
  showPrompt,
  disabled,
  disableCancelOnUnmount,
  googleConfig,
}) => {
  const script = useScript(googleClientScriptURL);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!window[scriptFlag] && window.google && script === 'ready') {
      window.google.accounts.id.initialize({
        ...googleConfig,
      });
      window[scriptFlag] = true;
    }
    if (window[scriptFlag] && script === 'ready' && !disabled) {
      window.google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' }, // customization attributes
      );

      if (showPrompt && !disabled) {
        window.google.accounts.id.prompt((notification) => {
          if (disabled) {
            console.log('disabled in prompt: ', disabled);
            window.google.accounts.id.cancel();
            return;
          }
          // onClick(true);
          console.log(notification);
          if (notification.isDisplayed()) {
            console.log('One Tap sign-in prompt was displayed to user');
            onDisplay(true);
          } else if (notification.isNotDisplayed()) {
            console.log('One Tap sign-in prompt was not shown to user: ');
            onDisplay(false);
          } else if (notification.isSkippedMoment()) {
            console.log('One Tap sign-in prompt was skipped by user', notification);
            onDisplay(false);
          } else if (notification.isDismissedMoment()) {
            console.log('One Tap sign-in prompt was dismissed by user');
            onDisplay(false);
          }
        });
      }
      return () => {
        if (!disableCancelOnUnmount) {
          window.google.accounts.id.cancel();
        }
      };
    }
  }, [script, window[scriptFlag], disabled]);

  return null;
};

export default useGoogleOneTap;
